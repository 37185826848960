import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Quelques explications sur les principales techniques de représentation de quantité sur les cartes. La plus récente étant de faire apparaitre un pic de hauteur proportionnée à la quantité.  `}</p>
    <iframe width="100%" height="785" frameBorder="0" src="https://observablehq.com/embed/@alainro/les-cartes-cest-un-vrai-probleme-et-jen-ai-marre-des-cercles?cell=viewof+seuil&cell=image"></iframe>
    <Link to="https://observablehq.com/@alainro/les-cartes-cest-un-vrai-probleme-et-jen-ai-marre-des-cercles" mdxType="Link">Le billet est à lire sur Observable</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      